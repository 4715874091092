import React, { useEffect } from "react";
import { Table, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listTeamsConsonant } from "../actions/teamActions";
import { PLAYER_SEASON_RECORD_RESET } from "../constants/playerConstants";
import { TEAM_DETAIL_RESET } from "../constants/teamConstants";
import Paginate from "../components/Paginate";
import Searcher from "../components/Searcher";

const TeamConsonantRecordScreen = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const teamListConsonant = useSelector((state) => state.teamListConsonant);
  const { loading, error, teams, page, pages } = teamListConsonant;

  useEffect(() => {
    dispatch({ type: TEAM_DETAIL_RESET });
    dispatch({ type: PLAYER_SEASON_RECORD_RESET });
    dispatch(listTeamsConsonant(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <div className="menu__header">
        <h2
          className="mt-3 mb-3"
          style={{ color: "white", "font-weight": 700 }}
        >
          팀 기록
        </h2>
        <p style={{ color: "white" }}>조회하고자 하는 팀명을 검색하세요</p>
      </div>
      <Searcher searchType={"teamrecord"} />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped hover responsive className="table-sm text-center mt-4">
          <thead>
            <tr className="table__header">
              <th></th>
              <th
                style={{
                  position: "sticky",
                  left: "0",
                  "z-index": "2",
                  "background-color": "#031b31",
                }}
              >
                팀명
              </th>
              <th>감독</th>
              <th>종별</th>
              <th>팀원수</th>
              <th>창단일</th>
              {userInfo && <th>가입요청</th>}
            </tr>
          </thead>
          <tbody>
            {teams &&
              teams.map((team) => (
                <tr key={team._id}>
                  <td>
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      {team.image === "" ? (
                        <Image
                          src={"/team_default_img.png"}
                          fluid
                          style={{ width: "30px", height: "30px" }}
                        />
                      ) : (
                        <Image
                          src={team.image}
                          alt={team.name}
                          fluid
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </div>
                  </td>
                  <td
                    className="align-middle"
                    style={{
                      position: "sticky",
                      left: "0",
                      "z-index": "1",
                      "background-color": "white",
                    }}
                  >
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      <Link to={`/team/${team._id}`}>{team.name}</Link>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      {team.leader ? team.leader : "-"}
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      {team.teamType}
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      {team.members.length}
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      {team.foundedAt ? team.foundedAt.substring(0, 10) : "-"}
                    </div>
                  </td>
                  {userInfo && (
                    <td
                      className="align-middle"
                      style={{
                        position: "sticky",
                        right: "0",
                        "z-index": "1",
                        "background-color": "white",
                      }}
                    >
                      <Link
                        class="btn btn-primary pull-right"
                        to={`/teamapply/${team._id}`}
                        style={{
                          height: "50%",
                          padding: "0",
                          width: "100%",
                          "font-size": "12px",
                        }}

                      >
                        가입요청
                      </Link>
                    </td>
                  )}
                  {userInfo && userInfo.isAdmin && (
                    <>
                      <td>
                        <Link
                          class="btn btn-primary pull-right"
                          to={`/admin/team/${team._id}`}
                          style={{
                            height: "60%",
                            padding: "0",
                            width: "40px",
                            "font-size": "12px",
                            marginRight: "5px",
                          }}
                        >
                          수정
                        </Link>
                        <Button
                          variant="danger"
                          class="btn btn-primary pull-right"
                          onClick={() => {
                            if (
                              window.confirm("정말로 삭제하시겠습니까?")
                            )
                              dispatch(deleteTeam(team._id));
                          }}
                          style={{
                            height: "60%",
                            padding: "0",
                            width: "40px",
                            "font-size": "12px",
                          }}
                        >
                          삭제
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Paginate
        pages={pages}
        page={page}
        keyword={keyword ? keyword : ""}
        pageType={"teamrecord-c"}
      />
    </>
  );
};

export default TeamConsonantRecordScreen;
