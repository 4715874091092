import axios from 'axios';
import { API_SERVER_URL } from './actionUrl';

// const API_SERVER_URL = "https://adek57e.time4.co.kr";

/**
 * @typedef {Object} NiceSession
 * @property {string} token_version_id
 * @property {string} enc_data
 * @property {string} integrity_value
 */

/**
 * @returns {Promise<NiceSession & { sid: string }> | Promise<null>}
 */
export const getNiceButtonSession = async () => {
    try {
        const res = await axios({
            method: 'POST',
            baseURL: API_SERVER_URL + '/',
            url: '/api/ident/nice/session?isTime4=true',
        });
        return res.data;
    } catch (/** @type {import('axios').AxiosError} */ err) {
        console.error(err);
        // if (err.status === 500) {
        //     // Handle 500 error if needed
        // }
        return null;
    }
}


/**
 * @typedef {Object} RegistrationFormDefaultValue
 * @property {string} name
 * @property {string} birthdate
 * @property {string} mobile
 */

/**
 * @param {string} sid
 * @returns {Promise<RegistrationFormDefaultValue> | Promise<null>}
 */
export const getNiceIdentificationResult = async (sid) => {
    try {
        const res = await axios({
            method: 'GET',
            baseURL: API_SERVER_URL,
            url: '/api/ident/nice/session',
            params: { sid }
        });
        return res.data;
    } catch (err) {
        console.error(err);
        // if (err.status === 500) {
        //     // Handle 500 error if needed
        // }
        return null;
    }
}
